<template>
	<div class="cn_customize">
		<div class="common-wrap">
			<h2 class="title">{{ contentData.tit }}</h2>
			<ul class="customize_list">
				<li v-for="(t, i) in contentData.list" :key="i">
					<a :href="t.href">
						<div class="top">
							<img :src="t.img" alt="" />
						</div>
						<div class="bottom">
							<h3>{{ t.tit }}</h3>
							<p>{{ t.des }}</p>
						</div>
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>
<script setup lang="ts">
const props = defineProps({
	contentData: {
		type: Object,
		default() {
			return {};
		}
	}
});

const localeLang = useLocaleLang();
</script>

<style lang="scss" scoped>
@import url("./CnCustomize.scss");
</style>
