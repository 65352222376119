<template>
	<section class="contact">
		<div class="contact-content" :class="{ sg_content: showSgContent }">
			<ul class="contact-list">
				<li v-for="(t, c) in connectData" :key="c" @click="gaPoint(t.tit)">
					<a v-if="t.live" id="bdtj_lxwm" href="javascript:;" @click="showLiveChat">
						<div class="lt">
							<img :src="t.img" alt="" />
						</div>
						<div class="rt">
							<div class="tit">
								<span>{{ t.tit }}</span>
								<i class="iconfont iconfs_2022111601icon" />
							</div>
							<div class="txt">{{ t.desc }}</div>
						</div>
					</a>
					<a v-else :href="localeLink(t.url)" :target="t.url.includes('https://wa.me') ? '_blank' : '_self'">
						<div class="lt">
							<img :src="t.img" alt="" />
						</div>
						<div class="rt">
							<div class="tit">
								<span>{{ t.tit }}</span>
								<i class="iconfont iconfs_2022111601icon" />
							</div>
							<div class="txt">{{ t.desc }}</div>
						</div>
					</a>
				</li>
				<!-- <li>
					<div>
						<img class="icon" :src="contactUs.right.icon" />
						<div>
							<a href="javascript:;" @click="showLiveChat">{{ contactUs.right.tit }}</a>
							<p>{{ contactUs.right.txt }}</p>
						</div>
					</div>
				</li> -->
				<!-- <li v-if="showSgContent">
					<div>
						<img class="icon" :src="sgData.icon" />
						<div>
							<a :href="sgData.href" @click="showLiveChat">{{ sgData.tit }}</a>
							<p>{{ sgData.txt }}</p>
						</div>
					</div>
				</li> -->
			</ul>
		</div>
	</section>
</template>

<script setup lang="ts">
import type { IHomeConnect } from "../../types";
defineOptions({
	name: "Service"
});
// 传参和路由信息
const props = defineProps<IHomeConnect>();
const connectData = props.contentData;

const localeLink = useLocaleLink();
const websiteStore = useWebsiteStore();

const { showLiveChat } = useLiveChat();

const { website } = storeToRefs(websiteStore);

const showSgContent = computed(() => website.value === "sg");

const gaPoint = function (c: string) {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: "uaEvent",
			eventCategory: "Home Page",
			eventAction: "contact_module",
			eventLabel: c,
			nonInteraction: false
		});
	}
};
</script>

<style lang="scss" scoped>
@import url("./Contact.scss");
</style>
