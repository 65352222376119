<template>
	<div class="software_wrap">
		<div class="common-wrap">
			<h2 class="title">{{ certified.title }}</h2>

			<div class="software_list">
				<swiper :modules="modules" :slidesPerView="slidesPerView" :spaceBetween="spaceBetween" :pagination="pagination">
					<swiper-slide v-for="(item, index) in list" :key="index">
						<div class="software-item">
							<a
								v-for="(t, i) in item"
								:key="i"
								:href="localeLink(t.url)"
								class="software_card"
								@click="gaPoint(t.tit)">
								<div class="img_box">
									<img :src="t.img" alt="" />
								</div>
								<div class="cont">
									<div>
										<div class="tit" :class="{ 'no-des': !t.desc }">{{ t.tit }}</div>
										<div v-if="t.desc" class="des">{{ t.desc }}</div>
									</div>
								</div>
							</a>
						</div>
					</swiper-slide>
				</swiper>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import type { IHomeSoftware } from "../../types";

const props = defineProps<IHomeSoftware>();

const localeLink = useLocaleLink();
const deviceStore = useDeviceStore();

const certified = props.contentData;

// Swiper配置
const modules = [Pagination];
const pagination = { clickable: true };
const slidesPerView = 1;
const spaceBetween = 16;

// 动态获取列表渲染数据
const list = computed(() =>
	deviceStore.screenWidth <= 768 ? ReorganizeArrays(certified.list, 1) : ReorganizeArrays(certified.list, 0)
);

const gaPoint = function (c: string) {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: "uaEvent",
			eventCategory: "Home Page",
			eventAction: "community_entrance",
			eventLabel: c,
			nonInteraction: false
		});
	}
};

onMounted(() => {
	console.log(props.contentData, "SoftwareData");
});
</script>

<style scoped lang="scss">
@import url("./Software.scss");
</style>
