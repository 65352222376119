<template>
	<div class="cn_success">
		<div class="common-wrap">
			<h2 class="title">{{ contentData.tit }}</h2>
			<ul class="success_list">
				<li v-for="(t, i) in contentData.list" :key="i">
					<a :href="t.href">
						<div>
							<div class="top">
								<img :src="t.img" alt="" />
							</div>
							<div class="bottom">
								<p>
									<i class="icon iconfont" v-html="t.icon"></i>
									<span v-for="(d, b) in t.des" :key="b">{{ d }}</span>
								</p>
								<div class="bottom_box">
									<h3>{{ t.tit }}</h3>
									<div>
										<div class="tag_box">
											<span v-for="(d, b) in t.tag" :key="b">{{ d }}</span>
										</div>
										<div class="href_box">
											{{ localeLang("homeLang.learnmore") }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>
<script setup lang="ts">
const props = defineProps({
	contentData: {
		type: Object,
		default() {
			return {};
		}
	}
});

const localeLang = useLocaleLang();
onMounted(() => {
	try {
		const newImg = [
			"https://resource.fs.com/mall/generalImg/202405131751042wy4yq.jpeg",
			"https://resource.fs.com/mall/generalImg/20231026162810am39rc.webp",
			"https://resource.fs.com/mall/generalImg/20231026162810bzmmwh.webp",
			"https://resource.fs.com/mall/generalImg/202405131751044j337l.jpeg"
		];
		props.contentData.list.forEach((item: any, index: number) => {
			item.img = newImg[index];
		});
	} catch (e) {
		console.log(e);
	}
});
</script>

<style lang="scss" scoped>
@import url("./CnSuccess.scss");
</style>
