<template>
	<div class="products">
		<div class="pc-product">
			<div ref="categoriesRef" class="categories">
				<ul v-if="products?.length">
					<li
						v-for="(c, i) in svgData"
						:key="i"
						ref="cate"
						:class="isActive === i ? 'active' : ''"
						@mouseenter.stop="svgChange(i)">
						<img
							:src="i === isActive ? c.hover : c.code"
							width="64"
							height="64"
							:alt="products[i] && products[i].name" />

						<p v-if="products[i]">
							<label ref="nameRef">{{ products[i].name }}</label>
							<span ref="lineRef" class="line"></span>
						</p>
					</li>
				</ul>
			</div>
			<div class="list">
				<div class="list_cont">
					<div class="swiper_box">
						<swiper
							v-if="shouldShowProduct"
							:modules="modules"
							:breakpoints="breakpoints"
							:pagination="pagination"
							:navigation="{
								nextEl: '.products .swiper-button-next',
								prevEl: '.products .swiper-button-prev'
							}"
							:class="{ 'swiper-no-swiping': !isTouch }"
							@swiper="setProductsRef">
							<swiper-slide v-for="(product, index) in list" :key="index">
								<div v-for="(t, i) in product" :key="i" class="product">
									<div class="pic">
										<a :href="t.url" @click.stop="GAPoint(isActive, t.url)">
											<img
												:src="t.image"
												:alt="t.name"
												@click.stop="
													localeLink(t.url);
													GAPoint(isActive, t.url);
												"
												@keyup.enter="localeLink(t.url)" />
										</a>
									</div>
									<div class="desc_box">
										<div
											class="tit"
											:title="t.name"
											:class="{ isNotEn: language !== 'English' }"
											@click.stop="localeLink(t.url)">
											<a :href="t.url" @click.stop="GAPoint(isActive, t.url)">{{ t.name }}</a>
											<i class="iconfont">&#xf203;</i>
										</div>
										<div v-if="t.children && t.children.length" class="category_items">
											<a
												v-for="items in t.children.slice(0, 5)"
												:key="items.id"
												:href="items.url"
												:title="items.name"
												:class="{ isHide: language !== 'English' }"
												@click.stop="GAPoint(isActive, items.url)"
												>{{ items.name }}</a
											>
										</div>
									</div>
								</div>
							</swiper-slide>
						</swiper>
						<div class="swiper-button-prev swiper-btn">
							<i class="iconfont iconfs_2020091148icon"></i>
						</div>
						<div class="swiper-button-next swiper-btn">
							<i class="iconfont iconfs_2020091147icon"></i>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue";

import { Pagination, Autoplay, Navigation, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import type SwiperClass from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import type { ProductsData } from "../../types";

const props = defineProps<ProductsData>();
const modules = [Pagination, Autoplay, Navigation, EffectFade];
const pagination = { clickable: true };
const breakpoints = {
	320: {
		slidesPerView: 1,
		spaceBetween: 20,
		slidesPerGroup: 1
	},
	769: {
		slidesPerView: 4,
		spaceBetween: 20,
		slidesPerGroup: 4
	}
};
const { language } = useWebsiteStore();
const localeLink = useLocaleLink();
const svgData = [
	{
		code: `https://resource.fs.com/mall/generalImg/20240426231055kkobgq.svg`,
		hover: `https://resource.fs.com/mall/generalImg/20240426231055kkobgq.svg`
	},
	{
		code: `https://resource.fs.com/mall/generalImg/20240426231055e2xsrn.svg`,
		hover: `https://resource.fs.com/mall/generalImg/20240426231055e2xsrn.svg`
	},
	{
		code: `https://resource.fs.com/mall/generalImg/202404262310556q8r7g.svg`,
		hover: `https://resource.fs.com/mall/generalImg/202404262310556q8r7g.svg`
	},
	{
		code: `https://resource.fs.com/mall/generalImg/20240426231055hhe5t6.svg`,
		hover: `https://resource.fs.com/mall/generalImg/20240426231055hhe5t6.svg`
	},
	{
		code: `https://resource.fs.com/mall/generalImg/20240426231055yqtkta.svg`,
		hover: `https://resource.fs.com/mall/generalImg/20240426231055yqtkta.svg`
	},
	{
		code: `https://resource.fs.com/mall/generalImg/202404262310550dq3tw.svg`,
		hover: `https://resource.fs.com/mall/generalImg/202404262310550dq3tw.svg`
	},
	{
		code: `https://resource.fs.com/mall/generalImg/202404262310553c4ug7.svg`,
		hover: `https://resource.fs.com/mall/generalImg/202404262310553c4ug7.svg`
	},
	{
		code: `https://resource.fs.com/mall/generalImg/20240426231055rdsscg.svg`,
		hover: `https://resource.fs.com/mall/generalImg/20240426231055rdsscg.svg`
	}
];

const products = props?.contentData?.data;
const deviceStore = useDeviceStore();

const shouldShowProduct = computed(() => {
	console.log(products[isActive.value], isActive.value, "isActive.value");

	const activeProduct = products[isActive.value];
	return products.length && activeProduct && activeProduct.children && activeProduct.children.length;
});

// 动态获取列表渲染数据
const list = computed(() =>
	deviceStore.screenWidth <= 768
		? ReorganizeArrays(products[isActive.value]?.children, 4)
		: ReorganizeArrays(products[isActive.value]?.children, 1)
);

const isTouch = ref(false);
watch(
	() => deviceStore.screenWidth,
	newVal => {
		isTouch.value = deviceStore.screenWidth <= 1024;
	},
	{
		immediate: true
	}
);
// 处理移动端自适应滑动
let solutionBanner: SwiperClass;
const setProductsRef = (swiper: SwiperClass) => {
	solutionBanner = swiper;
};
const solutionSwiper = computed(() => {
	return solutionBanner;
});
const categoriesRef = ref<HTMLElement | null>(null);
const isActive = ref(0);
const svgChange = (i: number) => {
	isActive.value = i;
	window.dataLayer &&
		window.dataLayer.push({
			event: "uaEvent",
			eventCategory: "Home Page",
			eventAction: "product_category",
			eventLabel: products[i].name,
			nonInteraction: false
		});
	solutionSwiper.value.slideTo(0);
	if (deviceStore.screenWidth < 768) {
		if (categoriesRef.value?.clientWidth) {
			const sum = getScrollLeft(isActive.value) - categoriesRef.value?.clientWidth;
			if (i < sum) {
				categoriesRef.value.scrollTo({
					left: sum,
					behavior: "smooth" // 这将确保滚动是平滑的
				});
			} else {
				categoriesRef.value.scrollTo({
					left: 0,
					behavior: "smooth" // 这将确保滚动是平滑的
				});
			}
		}
	}
};

// tab导航栏的红色下划线长度跟文字宽度保持一致
const lineRef = ref<HTMLSpanElement[]>([]);
const nameRef = ref<HTMLLabelElement[]>([]);
const initLineWidth = function () {
	products.forEach((item, index) => {
		if (nameRef.value[index].offsetWidth !== lineRef.value[index].offsetWidth) {
			lineRef.value[index].style.width = `${nameRef.value[index].offsetWidth}px`;
		}
	});
};

const GAPoint = function (i: number, url: string) {
	window.dataLayer &&
		window.dataLayer.push({
			event: "uaEvent",
			eventCategory: "Home Page",
			eventAction: "productCategoryList",
			eventLabel: `${products[i].name}_${url}`,
			nonInteraction: false
		});
};
onMounted(() => {
	initLineWidth();
	window.addEventListener("resize", initLineWidth);
});

onBeforeUnmount(() => {
	window.removeEventListener("resize", initLineWidth);
});

const getScrollLeft = (num: number) => {
	return 100 * (2 * num - 1);
};
</script>

<style lang="scss" scoped>
@import url("./Products.scss");
</style>
