<template>
	<div class="home-banner">
		<div class="p-home-banner" @mouseenter="swiperPause" @mouseleave="swiperStart">
			<swiper
				v-if="props?.contentData?.length"
				:modules="modules"
				class="swiper"
				:effect="'fade'"
				:pagination="pagination"
				:navigation="{
					nextEl: '.btn_box .swiper-button-next',
					prevEl: '.btn_box .swiper-button-prev'
				}"
				:loop="true"
				:longSwipesRatio="0.1"
				:observer="true"
				:observeParents="true"
				:observeSlideChildren="true"
				:autoplay="{
					delay: 3000,
					stopOnLastSlide: false,
					disableOnInteraction: false
				}"
				@swiper="setHomeBannerRef"
				@slideChangeTransitionStart="slideChangeTransitionStart">
				<swiper-slide v-for="(item, index) in props.contentData" :key="index" :swiperIndex="index">
					<a
						class="swiper-item"
						:style="{
							backgroundImage: `url(${deviceType === 0 ? item.img_pc_path : item.img_pad_path || item.img_pc_path})`
						}"
						:href="localeLink(item.url)"
						@click="gaBanner(item.content.title)">
						<div class="banner-content">
							<div class="banner_main_font white">
								<div class="text_area">
									<h2 v-if="item.content.title" class="tit" v-html="item.content.title"></h2>
									<p v-if="item.content.content" class="txt" v-html="item.content.content"></p>
								</div>
								<ul v-if="item.content.list?.length" class="price_list">
									<li v-for="(p_item, p_index) in item.content.list" :key="p_index">
										<b>{{ p_item[0] }}</b>
										<span> {{ p_item[1] }}</span>
									</li>
								</ul>
								<div class="btn">
									<FsButton
										v-if="item.content.button_text"
										type="white"
										plain
										tabindex="0"
										class="banner_main_font_more banner_main_learn"
										@click="gaLearnMore(item.content.title)">
										<span class="btn_info" v-html="item.content.button_text"></span>
										<i class="iconfont">&#xf047;</i>
									</FsButton>
									<FsButton
										v-if="item.videoPath"
										type="white"
										text
										tabindex="0"
										plain
										class="banner_main_font_more banner_main_play_video"
										@click.prevent.stop="playVideo(item, $event)">
										<!-- <span class="btn_info" v-html="`${localeLang('homeLang.Watch_video')} (${videoInfo.time})`"></span> -->
										<object>
											<a
												class="btn_info"
												:href="item.videoPath"
												v-html="`${localeLang('homeLang.Watch_video')} (${videoInfo.time})`"></a>
										</object>
									</FsButton>
								</div>
							</div>
						</div>
					</a>
				</swiper-slide>
			</swiper>
			<div class="btn_box">
				<div class="swiper-button-prev swiper-btn">
					<i class="iconfont iconfs_2020091148icon"></i>
				</div>
				<div class="swiper-button-next swiper-btn">
					<i class="iconfont iconfs_2020091147icon"></i>
				</div>
			</div>
		</div>
		<div class="m-home-banner">
			<swiper
				v-if="props?.contentData?.length"
				:modules="modules"
				:pagination="pagination"
				:loop="true"
				:autoplay="{
					delay: 3000,
					stopOnLastSlide: false,
					disableOnInteraction: false
				}">
				<swiper-slide v-for="(c, i) in props.contentData" :key="i">
					<a class="swiper-item" :href="localeLink(c.url)">
						<img :src="c.img_mobile_path" />
					</a>
				</swiper-slide>
			</swiper>
		</div>
		<FsDialog
			v-model="dialogVisible"
			className="home_banner_video_dialog_div"
			:title="videoInfo.title"
			:customDialogStyle="customDialogStyle"
			:headerStyle="headerStyle"
			:contentStyle="contentStyle">
			<template #title>
				<div class="fs_dialog_title">{{ website === "en" ? `FS Warehouse and Service Center` : videoInfo.title }}</div>
			</template>
			<FsVideo v-if="dialogVisible" id="video-player2" autoplay :src="videoInfo.url" />
			<div v-if="dialogVisible" class="close_icon" @click="closeClicked">
				<i class="iconfont iconfont-close">&#xf041;</i>
			</div>
		</FsDialog>
	</div>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue";
import { FsButton, FsDialog, FsVideo } from "fs-design";

import { Pagination, Autoplay, Navigation, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import type SwiperClass from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import type { BannerList, Banner } from "../../types";
const props = defineProps<BannerList>();

// 自适应修改pc、pad背景图
const websiteStore = useWebsiteStore();
const { website } = storeToRefs(websiteStore);

const localeLang = useLocaleLang();
const localeLink = useLocaleLink();
const deviceStore = useDeviceStore();
const deviceType = computed(() => {
	const num = ref(0);
	if (deviceStore.screenWidth >= 768 && deviceStore.screenWidth <= 1024) {
		num.value = 1;
	} else {
		num.value = 0;
	}
	return num.value;
});

// swiper配置
const modules = [Pagination, Autoplay, Navigation, EffectFade];
const pagination = { clickable: true };
let homeBanner: SwiperClass;
const setHomeBannerRef = (swiper: SwiperClass) => {
	homeBanner = swiper;
};
const bannerSwiper = computed(() => {
	return homeBanner;
});
type SwiperSlideList = NodeListOf<Element>;

const slideChangeTransitionStart = () => {
	// console.log("滑动开始", bannerSwiper?.value?.realIndex);
	const pcHomeBanner: Element = document.querySelector(".p-home-banner") as HTMLElement;
	const slideArr: SwiperSlideList = pcHomeBanner.querySelectorAll(".swiper-slide");
	const realIndex = Number(bannerSwiper?.value?.realIndex);

	slideArr.forEach((item: Element, index: Number) => {
		const swiperindex = Number(item.getAttribute("swiperindex"));
		const text_area: Element = item.querySelector(".text_area") as Element;
		if (realIndex > swiperindex) {
			text_area?.classList.remove("to-right");
			text_area?.classList.add("to-left");
		} else {
			text_area?.classList.remove("to-left");
			text_area?.classList.add("to-right");
		}
		if (realIndex === slideArr.length - 1) {
			const firstSlideContainer: HTMLElement = document.querySelector('div[swiperindex="0"]') as HTMLElement;
			const firstSlide: HTMLElement = firstSlideContainer?.querySelector(".text_area") as HTMLElement;
			firstSlide?.classList.remove("to-left");
			firstSlide?.classList.add("to-right");
		} else if (realIndex === 0) {
			const lastSlideContainer: HTMLElement = document.querySelector(
				`div[swiperindex="${slideArr.length - 1}"]`
			) as HTMLElement;
			const lastSlide: HTMLElement = lastSlideContainer?.querySelector(".text_area") as HTMLElement;
			lastSlide?.classList.remove("to-right");
			lastSlide?.classList.add("to-left");
		}
	});
};

const initSwiperClass = () => {
	// 初始化swiper动效class
	const slideArr: SwiperSlideList = document.querySelectorAll(".swiper-slide");
	slideArr.forEach((item: Element, index: Number) => {
		const text_area: Element = item.querySelector(".text_area") as Element;
		if (index === 5) {
			text_area?.classList.remove("to-right");
			text_area?.classList.add("to-left");
		} else {
			text_area?.classList.remove("to-left");
			text_area?.classList.add("to-right");
		}
	});
};

const videoInfo = {
	title: "FS Global Warehouse Introduction",
	url: "https://resource.fs.com/video/202405301731525j8pdd.mp4",
	time: "01:28"
};

const dialogVisible = ref<Boolean>(false);
const playVideo = (item: Banner, event: MouseEvent) => {
	event.stopPropagation();
	videoInfo.title = item.content.title;
	videoInfo.url = item.videoPath;
	dialogVisible.value = true;
};

const customDialogStyle = {
	background: "#000000"
};
const headerStyle = {
	padding: "14px 32px",
	display: "none"
};
const contentStyle = {
	padding: 0
};

const swiperPause = () => {
	bannerSwiper?.value.autoplay.stop();
};
const swiperStart = () => {
	bannerSwiper?.value.autoplay.start();
};

const gaBanner = function (c: string) {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: "uaEvent",
			eventCategory: "Home Page",
			eventAction: "top_banner_entrance",
			eventLabel: `img_${c}`,
			nonInteraction: false
		});
	}
};
const closeClicked = function () {
	dialogVisible.value = false;
};
const gaLearnMore = function (c: string) {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: "uaEvent",
			eventCategory: "Home Page",
			eventAction: "top_banner_entrance",
			eventLabel: `Learn more_${c}`,
			nonInteraction: false
		});
	}
};
const initLineWidth = function () {
	if (window.innerWidth <= 768) {
		dialogVisible.value = false;
	}
};

onMounted(() => {
	initSwiperClass();
	console.log(props, props?.contentData, "bannerData");
	initLineWidth();
	window.addEventListener("resize", initLineWidth);
});
onBeforeUnmount(() => {
	window.removeEventListener("resize", initLineWidth);
});
</script>

<style scoped lang="scss">
@import url("./Banner.scss");
</style>

<style lang="scss">
.home_banner_video_dialog_div {
	&.fs-dialog__content {
		width: 100% !important;
		max-width: 75%;
		max-height: 100% !important;
		aspect-ratio: 1440/810 !important;
		position: absolute;
		overflow: visible !important;
		#video-player2 {
			width: 100% !important;
			height: 100% !important;
			cursor: default;
		}
		video {
			object-fit: cover;
		}
	}
}
</style>
