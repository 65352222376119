<template>
	<main class="home-wrap">
		<Banner :contentData="bannerData"></Banner>
		<Products :contentData="productsData"></Products>
		<div class="gray_line"></div>
		<template v-if="!isCn">
			<Software :contentData="softwareData"></Software>
			<Solutions :contentData="solutionData"></Solutions>
			<Certified :contentData="certifiedData"></Certified>
			<case-study :contentData="caseStudyData"></case-study>
		</template>
		<template v-if="isCn">
			<cn-company :contentData="cnCompanyData"></cn-company>
			<cn-customize :contentData="cnCustomizeData"></cn-customize>
			<cn-solution :contentData="cnSolutionData"></cn-solution>
			<cn-success :contentData="cnSuccessData"></cn-success>
		</template>
		<Contact :contentData="connectData"></Contact>
	</main>
</template>

<script setup lang="ts">
import { ref } from "vue";
import Banner from "./components/Banner/Banner.vue";
import Products from "./components/Products/Products.vue";
import Solutions from "./components/Solutions/Solutions.vue";
import Software from "./components/Software/Software.vue";
import Certified from "./components/Certified/Certified.vue";
import CaseStudy from "./components/CaseStudy/CaseStudy.vue";
import Contact from "./components/Contact/Contact.vue";

import CnCompany from "./components/CnCompany/CnCompany.vue";
import CnCustomize from "./components/CnCustomize/CnCustomize.vue";
import CnSolution from "./components/CnSolution/CnSolution.vue";
import CnSuccess from "./components/CnSuccess/CnSuccess.vue";
import { homePV } from "@/utils/burialPoint";
definePageMeta({
	layout: "common"
});

const websiteStore = useWebsiteStore();
const { website } = storeToRefs(websiteStore);
const setMeta = useSetMeta();

const isCn = computed(() => {
	return ["cn", "hk", "mo", "tw"].includes(website.value);
});

const bannerData = ref([]);
const productsData = ref();
const serviceSupportData = ref();
// const whyUsData = ref();
const caseStudyData = ref();
// const contactUsData = ref();
// 首页改版新增变量
const certifiedData = ref();
const connectData = ref([]);
const solutionData = ref();
// const storiesData = ref();
const softwareData = ref();
// Software

// 中文站数据
const cnCompanyData = ref();
const cnCustomizeData = ref();
const cnSolutionData = ref();
const cnSuccessData = ref();

const getIndexData = async () => {
	// const indexData = await useRequest.get(`/api/content`);

	const indexData = await useRequest.post(`/static`, {
		data: {
			method: "GET",
			url: "/api/content",
			filterId: "home",
			moduleName: "home" // 模块名称
		}
	});

	// console.log(indexData, "indexData");

	const getData = indexData?.data?.value?.data;
	// console.log(getData, "getData");

	if (getData) {
		bannerData.value = getData.banner;
		productsData.value = getData.featureData;
		serviceSupportData.value = getData.service_and_support;
		// whyUsData.value = getData.networks_move_businesses;
		caseStudyData.value = getData.customer_success;
		// contactUsData.value = getData.connect;
		certifiedData.value = getData.certified;
		connectData.value = getData.connect;
		solutionData.value = getData.solution;
		// storiesData.value = getData.stories;
		softwareData.value = getData.software;
		cnCompanyData.value = getData.cnBlock.company;
		cnCustomizeData.value = getData.cnBlock.service;
		cnSolutionData.value = getData.cnBlock.solution;
		cnSuccessData.value = getData.cnBlock.success;
		if (getData.meta) {
			setMeta(getData.meta);
		}
	}
};

const schemaWebsite = {
	"@context": "https://schema.org",
	"@type": "WebSite",
	name: "FS.com",
	url: `https://www.fs.com/${website.value === "en" ? "website.value" : ""}`
};

const schemaBreadcrumb = {
	type: "application/ld+json",
	json: {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				item: {
					"@type": "Thing",
					"@id": `https://www.fs.com/${website.value === "en" ? "" : website.value}`,
					name: "Home"
				},
				position: 0
			},
			{
				"@type": "ListItem",
				item: {
					"@type": "Thing",
					"@id": `https://www.fs.com/${website.value === "en" ? "" : website.value}`,
					name: "Home"
				},
				position: 1
			}
		]
	}
};

useHead({
	script: [
		{
			type: "application/ld+json",
			children: JSON.stringify(schemaWebsite)
		},
		{
			type: "application/ld+json",
			children: JSON.stringify(schemaBreadcrumb)
		}
	]
});

onMounted(() => {
	homePV();
});

await getIndexData();
useHeadMeta();
</script>
<style lang="scss" scoped>
.gray_line {
	@include width1420;
	max-width: 1200px;
	margin: 0 auto;
	height: 1px;
	background-color: $bgColor7;
}
@include mobile {
	.gray_line {
		width: calc(100% - 32px);
	}
}
</style>
