<template>
	<div class="cn_solutions">
		<div class="common-wrap">
			<h2 class="title">{{ contentData.tit }}</h2>
			<div class="nav_list_box">
				<ul class="nav_list">
					<li v-for="(t, i) in contentData.nav" :key="i" :class="{ active: active == i }">
						<span @click="chooseIndex(i, t)">{{ t }}</span>
					</li>
				</ul>
			</div>
			<client-only>
				<div class="solutions_list swiper-no-swiping">
					<swiper
						:modules="modules"
						:slidesPerView="slidesPerView"
						:spaceBetween="spaceBetween"
						@swiper="setHomeSolutionRef">
						<swiper-slide v-for="(bean, tree) in contentData.list" :key="tree">
							<div class="lt">
								<img :src="bean.banner" alt="" />
							</div>
							<div class="rt">
								<div v-if="bean.labels">
									<p v-for="(item, index) in bean.labels" :key="index">
										<img v-show="item.icon" :src="item.icon" alt="" />
										<span>{{ item.des }}</span>
									</p>
								</div>
								<h3>{{ bean.tit }}</h3>
								<p :class="{ mb24: bean.labels }">{{ bean.des }}</p>
								<ul v-if="bean.list">
									<li v-for="(item, index) in bean.list" :key="index">
										<img :src="item.icon" alt="" />
										<span>{{ item.des }}</span>
									</li>
								</ul>
								<a :href="bean.href">
									<!-- <fs-button type="blacktext-link" class="btn">
										{{ localeLang("homeLang.learnmore") }}
									</fs-button> -->
									<FsButton iconPlacement="suffix" text type="primary">
										<template #default>{{ localeLang("homeLang.learnmore") }}</template>
										<template #icon>
											<i class="iconfont iconfs_2020091147icon" />
										</template>
									</FsButton>
								</a>
							</div>
						</swiper-slide>
					</swiper>
				</div>
			</client-only>
		</div>
	</div>
</template>
<script setup lang="ts">
import { FsButton } from "fs-design";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import type SwiperClass from "swiper";

const modules = [Pagination];
const pagination = { clickable: true };
const slidesPerView = 1;
const spaceBetween = 16;
let solutionBanner: SwiperClass;
const setHomeSolutionRef = (swiper: SwiperClass) => {
	solutionBanner = swiper;
};
const solutionSwiper = computed(() => {
	return solutionBanner;
});

const props = defineProps({
	contentData: {
		type: Object,
		default() {
			return {};
		}
	}
});

const localeLang = useLocaleLang();

const active = ref(0);
const chooseIndex = function (i: number, name: string) {
	active.value = i;
	try {
		solutionSwiper?.value.slideTo(active.value, 0, false);
	} catch (e) {}
};
</script>

<style lang="scss" scoped>
@import url("./CnSolution.scss");
</style>
