<template>
	<div class="customer_success">
		<div class="fs-cases">
			<h2 class="title">{{ caseStudyData.tit }}</h2>
			<div class="fs-cases-list">
				<swiper :modules="modules" :slidesPerView="slidesPerView" :spaceBetween="spaceBetween" :pagination="pagination">
					<swiper-slide v-for="(item, index) in list" :key="index">
						<div class="fs-cases-item">
							<a v-for="(t, i) in item" :key="i" :href="t.url" @click="gaPoint(t.content)">
								<div class="case_item_content">
									<div class="lt">
										<img :src="t.img" />
									</div>
									<div class="rt">
										<div class="tags">
											<fs-tag InteractiveTag iconTag color="#707070">
												<!-- <template #prefix>
													<img :src="t.tag_new" />
												</template> -->
												<span>{{ t.country_name }}</span>
											</fs-tag>
										</div>
										<div class="bottom">
											<p class="title">{{ t.content }}</p>
											<ClientOnly>
												<p class="desc">
													<template v-for="(tip, t_index) in t.tips" :key="t_index">
														<fs-tag color="#707070" size="small">
															<span>{{ tip }}</span>
														</fs-tag>
														<FsDivider direction="vertical"></FsDivider>
													</template>
												</p>
											</ClientOnly>
										</div>
									</div>
								</div>
							</a>
						</div>
					</swiper-slide>
				</swiper>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { FsTag, FsDivider } from "fs-design";

import { computed } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import type { IHomeCaseStudy } from "../../types";

// 传参设备路由数据
const props = defineProps<IHomeCaseStudy>();
const caseStudyData = props.contentData;
const deviceStore = useDeviceStore();
// Swiper配置
const modules = [Pagination];
const pagination = { clickable: true };
const slidesPerView = 1;
const spaceBetween = 16;
// 动态获取列表渲染数据
const list = computed(() =>
	deviceStore.screenWidth <= 768 ? ReorganizeArrays(caseStudyData.list, 1) : ReorganizeArrays(caseStudyData.list, 0)
);

const gaPoint = function (c: string) {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: "uaEvent",
			eventCategory: "Home Page",
			eventAction: "case_module",
			eventLabel: c,
			nonInteraction: false
		});
	}
};

onMounted(() => {
	console.log(caseStudyData, "caseStudyData");
});
</script>

<style lang="scss" scoped>
@import url("./CaseStudy.scss");
</style>
